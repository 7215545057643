// Prevent conflicts between jQuery and other javascript libraries
jQuery.noConflict();

jQuery(document).ready(function ($) {
    // Responsive image maps for the front page
    $("map").imageMapResize();

    // Footer Alert
    var footerAlert = {
        settings: {
            banner: $(".footer-main__alert"),
            close: $(".footer-main__alert .alert-close"),
            cookieString:
                "bannerHidden=true; expires=" +
                new Date(Date.now() + 86400e3).toUTCString(),
            allCookies: document.cookie.split(";"),
        },

        init: function () {
            if (document.cookie.match("bannerHidden")) {
                return;
            } else {
                footerAlert.settings.banner.addClass("active");
                footerAlert.settings.close.on("click", function () {
                    footerAlert.settings.banner.fadeToggle(300);
                    document.cookie = footerAlert.settings.cookieString;
                });
            }
        },
    };

    footerAlert.init();

    // ===== GLOBAL HELPER OBJECT
    // ================================================================================

    var globalHelper = {
        // Determines if an elements exists on the page
        elementExists: function (e) {
            if (e.length > 0) {
                return true;
            } else {
                return false;
            }
        },

        // Determines if an element is out of the viewport
        isElementOutOfView: function (e) {
            var vals = {
                eTop: e.offset().top,
                winTop: $(window).scrollTop(),
                winHeight: $(window).height(),
            };
            if (
                vals.winTop <= vals.eTop &&
                vals.eTop < vals.winTop + vals.winHeight
            ) {
                return false;
            } else {
                return true;
            }
        },

        // Scrolls the browser to the top of an element
        scrollTo: function (e, duration) {
            duration = typeof duration !== "undefined" ? duration : 500;
            $("html,body").animate({ scrollTop: e.offset().top }, duration);
        },

        // Returns a function that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // N milliseconds. If 'immediate' is passed, trigger the function on the
        // leading edge, instead of the trailing.
        // Use:  $(window).resize(globalHelper.debounce(function() { ...[your code here]... }, 500));
        // Source:  http://davidwalsh.name/javascript-debounce-function
        debounce: function (func, wait, immediate) {
            var timeout;
            return function () {
                var context = this,
                    args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) {
                        func.apply(context, args);
                    }
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) {
                    func.apply(context, args);
                }
            };
        },
    };

    // ===== GLOBAL ELEMENTS / FEATURES
    // ================================================================================

    var globalFeatures = {
        settings: {
            empty_p: $("p:empty"),
        },
        init: function () {
            this.removeEmptyPTags();
        },

        // Removes empty <p> tags from the page
        removeEmptyPTags: function () {
            this.settings.empty_p.remove();
        },
    };
    globalFeatures.init();

    // ===== Form Redirect
    // ================================================================================

    $("#pharmacyid").change(function () {
        if ($(this).val() == "7058305") {
            window.location.replace(
                "http://cannonpharmacies.com/concord-rx-refill/?loc=concord"
            );
        }
    });

    $("#pharmacyid").change(function () {
        if ($(this).val() == "7058308") {
            window.location.replace(
                "http://cannonpharmacies.com/concord-rx-refill/?loc=north+kannapolis"
            );
        }
    });

    $("#pharmacyid").change(function () {
        if ($(this).val() == "7058307") {
            window.location.replace(
                "http://cannonpharmacies.com/concord-rx-refill/?loc=mooresville"
            );
        }
    });

    $("#pharmacyid").change(function () {
        if ($(this).val() == "7058309") {
            window.location.replace(
                "http://cannonpharmacies.com/concord-rx-refill/?loc=south+end"
            );
        }
    });

    // ===== CONTACT FORMS
    // ================================================================================

    var contactForm = {
        settings: {
            input: $(".form-label-inline .input-text"),
            uniform_elems: "select",
            gravity_form: $(".gform_wrapper"),
        },
        init: function () {
            this.bindUIActions();
            this.uniformInit();
            this.gravityFormListener();
            this.setInitialState();
        },
        bindUIActions: function () {
            this.settings.input.on("focus blur", function (e) {
                contactForm.toggleLabelPosition($(this), e);
            });
        },

        // Applies uniform styling to form elements (i.e. <select>, etc.)
        uniformInit: function () {
            $(this.settings.uniform_elems).uniform({
                selectAutoWidth: false,
            });
        },

        // Looks for the presence of values witin form fields to determine if form
        // aesthetics (e.g. labels) need to change.
        setInitialState: function () {
            this.settings.input.each(function (i) {
                if ($(this).val() != "") {
                    $(this).parent(".form-row").addClass("label-up");
                }
            });
        },

        // Determines the position of a field's label based on focus and value
        toggleLabelPosition: function (elem, e) {
            if (e.type == "focus") {
                elem.parent(".form-row").addClass("label-up");
            }
            if (e.type == "blur" && elem.val() == "") {
                elem.parent(".form-row").removeClass("label-up");
            }
        },

        gravityFormListener: function () {
            if (globalHelper.elementExists(this.settings.gravity_form)) {
                jQuery(document).on(
                    "gform_post_render",
                    function (event, form_id, current_page) {
                        contactForm.uniformInit();
                    }
                );
            }
        },
    };
    contactForm.init();

    // ===== HEADER - MAIN NAVIGATION
    // ================================================================================

    var headerNav = {
        settings: {
            nav: $(".header-nav"),
            nav_ul: $(".header-nav-body > ul"),
            nav_menu_items: $(".header-nav-body > ul li"),
            nav_trigger: $(".header-nav-trigger"),
            subnav_trigger: $(".header-nav .subnav-trigger"),
            sf: {
                // Superfish
                instance: {},
                options: {
                    delay: 100,
                    speed: 200,
                    speedOut: 0,
                    cssArrows: false,
                },
            },
        },
        init: function () {
            this.bindUIActions();
            this.sfInit();
            this.findMenuItemsWithChildren();
        },
        bindUIActions: function () {
            this.settings.nav_trigger.on("touchstart click", function (e) {
                e.preventDefault(); // prevent double-firing on touch
                headerNav.toggleMobileMenu();
            });
            this.settings.subnav_trigger.on("touchstart click", function (e) {
                e.preventDefault(); // prevent double-firing on touch
                var subnav = $(this).parent("a").parent("li");
                headerNav.toggleSubNav(subnav);
                return false;
            });
        },

        findMenuItemsWithChildren: function () {
            this.settings.nav_menu_items.each(function (i) {
                if (globalHelper.elementExists($(this).find(".sub-menu"))) {
                    $(this).addClass("menu-item-has-children");
                }
            });
        },

        // Expand/Collapse the main navigation menu for mobile users
        toggleMobileMenu: function () {
            this.settings.nav.toggleClass("active");
            // Collapse all submenus when the main menu is closed
            if (!this.settings.nav.hasClass("active")) {
                this.settings.nav.find("li").removeClass("active");
            }
        },

        // Expand/Collapse any subnav menu for mobile users
        toggleSubNav: function (subnav) {
            subnav.toggleClass("active");
            // Collapse any children if the parent is collapsed
            if (!subnav.hasClass("active")) {
                subnav.find("li").removeClass("active");
            }
        },

        // Initialize Superfish functionality on the main navigation menu
        sfInit: function () {
            this.settings.sf.instance = $(this.settings.nav_ul).superfish(
                this.settings.sf.options
            );
        },

        // Restore Superfish functionality on the main navigation menu
        sfRestore: function () {
            this.settings.sf.instance.superfish(this.settings.sf.options);
            // Reset the state of the mobile menu, in the event that submenus were open.
            this.settings.nav.removeClass("active");
            this.settings.nav.find("li").removeClass("active");
        },

        // Destroy Superfish functionality on the main navigation menu
        sfDestroy: function () {
            this.settings.sf.instance.superfish("destroy");
        },
    };
    headerNav.init();

    // ===== HOME PAGE - RESPONSIVE IMAGERY
    // ================================================================================

    var pageHomeResponsiveImg = {
        settings: {
            required_element: $(".home-hero"),
            response: {
                // Response JS
                options: {
                    prop: "width",
                    prefix: "src",
                    breakpoints: [960],
                    lazy: true,
                },
            },
        },
        init: function () {
            if (globalHelper.elementExists(this.settings.required_element)) {
                this.responseInit();
            }
        },

        // Initialize Response JS for responsive content management (e.g. image swapping for art direction)
        responseInit: function () {
            Response.create(this.settings.response.options);
        },
    };
    pageHomeResponsiveImg.init();

    // ===== HOME PAGE - HERO SLIDER
    // ================================================================================

    var pageHomeHero = {
        settings: {
            required_element: $(".home-hero"),
            slider: $(".home-hero-slider"),
            slider_ctrl_next: $(".home-hero-ctrl.next"),
            slider_ctrl_prev: $(".home-hero-ctrl.prev"),
            slide_first_height: 0,
            bx: {
                // bxSlider
                instance: {},
                options: {
                    adaptiveHeight: true,
                    auto: true,
                    autoHover: true,
                    controls: false,
                    mode: "fade",
                    pager: false,
                    pause: 8000,
                },
            },
        },
        init: function () {
            if (globalHelper.elementExists(this.settings.required_element)) {
                this.bxInit();
                this.bindUIActions();
            }
        },
        bindUIActions: function () {
            this.settings.slider_ctrl_next.on("touchstart click", function (e) {
                e.preventDefault(); // prevent double-firing on touch
                pageHomeHero.bxStop();
                pageHomeHero.bxNextSlide();
            });
            this.settings.slider_ctrl_prev.on("touchstart click", function (e) {
                e.preventDefault(); // prevent double-firing on touch
                pageHomeHero.bxStop();
                pageHomeHero.bxPrevSlide();
            });
        },

        // bxSlider:  initialize slider
        bxInit: function () {
            this.settings.bx.instance = this.settings.slider.bxSlider(
                this.settings.bx.options
            );
        },

        // bxSlider:  stop the auto-play
        bxStop: function () {
            this.settings.bx.instance.stopAuto();
        },

        // bxSlider:  go to the next slide
        bxNextSlide: function () {
            this.settings.bx.instance.goToNextSlide();
        },

        // bxSlider:  go to the previous slide
        bxPrevSlide: function () {
            this.settings.bx.instance.goToPrevSlide();
        },
    };
    pageHomeHero.init();

    // ===== HOME PAGE - TESTIMONIALS
    // ================================================================================

    var pageHomeTestimonials = {
        settings: {
            required_element: $(".home-section-testimonials"),
            slider: $(".home-testimonials"),
            bx: {
                // bxSlider
                instance: {},
                options: {
                    // adaptiveHeight: true,
                    auto: true,
                    autoHover: true,
                    controls: false,
                    mode: "fade",
                    pause: 15000,
                    randomStart: true,
                },
            },
        },
        init: function () {
            if (globalHelper.elementExists(this.settings.required_element)) {
                this.bxInit();
            }
        },

        // bxSlider:  initialize slider
        bxInit: function () {
            this.settings.bx.instance = this.settings.slider.bxSlider(
                this.settings.bx.options
            );
        },
    };
    pageHomeTestimonials.init();

    // ===== PAGE - LOCATIONS
    // ================================================================================

    var pageLocations = {
        settings: {
            required_element: $(".page-id-5"),
        },
        init: function () {
            if (globalHelper.elementExists(this.settings.required_element)) {
                this.hashHandler();
            }
        },

        // Look for the presence of hash-tags that match locations
        hashHandler: function () {
            var hash = window.location.hash
                .substring(1)
                .replace("-", " ")
                .toLowerCase();
            var loc = "";
            if (hash) {
                $(window).load(function () {
                    $(".mapp-title").each(function (i) {
                        loc = $(this).text().trim().toLowerCase();
                        if (loc.indexOf(hash) != -1) {
                            $(this).children("a").trigger("click");
                        }
                    });
                });
            }
        },
    };
    pageLocations.init();

    // ===== PAGE - LOCATIONS
    // ================================================================================

    var pageLocations = {
        settings: {
            required_element: $(".page-id-20"),
            coupon: $(".coupons .coupon"),
        },
        init: function () {
            if (globalHelper.elementExists(this.settings.required_element)) {
                this.bindUIActions();
            }
        },
        bindUIActions: function () {
            this.settings.coupon.on("click", function () {
                window.print();
                return false;
            });
        },
    };
    pageLocations.init();

    // ===== PAGE - ONLINE RX REFILL
    // ================================================================================

    var pageRxRefill = {
        settings: {
            required_element: $(".page-id-68"),
            form: $(".rx-form"),
            form_submit_btn: $(".rx-form .btn-submit"),
            form_loading: $(".rx-form .loading"),
            form_msg: $(".rx-form .msg"),
        },
        init: function () {
            if (globalHelper.elementExists(this.settings.required_element)) {
                this.bindUIActions();
            }
        },
        bindUIActions: function () {
            this.settings.form.on("submit", function () {
                pageRxRefill.submitForm();
                return false;
            });
        },

        // Submit the form via AJAX
        submitForm: function () {
            this.settings.form_loading.show();
            this.settings.form_submit_btn.prop("disabled", 1);
            this.settings.form_msg.html("");
            var request = {
                pharmacyid: $('select[name="pharmacyid"]').val(),
                lastname: $('input[name="lastname"]').val(),
                dobmonth: $('select[name="dobmonth"]').val(),
                dobday: $('select[name="dobday"]').val(),
                dobyear: $('select[name="dobyear"]').val(),
                phone: $('input[name="phone"]').val(),
                rxnumber: $('input[name="rxnumber"]').val(),
                recievemethod: $('select[name="recievemethod"]').val(),
            };
            console.log("fired");
            gtag("event", "Submit", { event_category: "RX Refill Form" });
            $.ajax({
                url:
                    "/wp-content/themes/cannonpharmacies-v2/library/ajax/refill.php",
                type: "post",
                data: request,
                success: function (response) {
                    setTimeout(function () {
                        pageRxRefill.settings.form_loading.hide();
                        pageRxRefill.settings.form_submit_btn.prop(
                            "disabled",
                            ""
                        );
                        pageRxRefill.settings.form_msg.html(response).show();
                        location.hash = "refill";
                        if (
                            globalHelper.isElementOutOfView(
                                pageRxRefill.settings.form
                            )
                        ) {
                            globalHelper.scrollTo(pageRxRefill.settings.form);
                        }
                    }, 1000);
                },
            });
        },
    };
    pageRxRefill.init();

    // ===== RESPONSIVE JAVASCRIPT MANAGEMENT
    // ================================================================================

    var jRes = new jRespond([
        { label: "4col", enter: 0, exit: 639 },
        { label: "8col", enter: 640, exit: 959 },
        { label: "12col", enter: 960, exit: 1279 },
        { label: "16col", enter: 1280, exit: 10000 },
    ]);

    jRes.addFunc([
        {
            breakpoint: ["4col", "8col", "12col"],
            enter: function () {
                // Destroy Superfish functionality on main navigation
                headerNav.sfDestroy();
            },
            exit: function () {
                // Restore Superfish functionality on main navigation
                headerNav.sfRestore();
            },
        },
        {
            breakpoint: ["12col", "16col"],
            enter: function () {},
            exit: function () {},
        },
        {
            breakpoint: "4col",
            enter: function () {},
            exit: function () {},
        },
        {
            breakpoint: "8col",
            enter: function () {},
            exit: function () {},
        },
        {
            breakpoint: "12col",
            enter: function () {},
            exit: function () {},
        },
        {
            breakpoint: "16col",
            enter: function () {},
            exit: function () {},
        },
    ]);
});
